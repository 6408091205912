$header-background: #f58e7fd6;
$body-background: #FDE1DB;
$stars-overlay: #292c4eba;
$footer-background: #2B2E56;
$body-font: #666666;
$dark-pink: #D38579;
$dark-pink-hover: #dc7363;
$pale-pink: #FDE1DB;
$form-field-hover: #ffffffa3;
$menu-hover: #D38579;
$blue: #2E31A6;
$navy: #2B2E56;