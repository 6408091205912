.quicklinks .navbar-nav.flex-row {
    display: flex;
    justify-content: space-between;
}
.quicklinks .nav-link.flex-column {
    display: flex;
    flex-flow: column nowrap;
}
.quicklinks svg {
    fill:$dark-pink;
    height: 7.5vw;
    max-height: 170px;
}
.quicklinks .title {
    color: $dark-pink;
    margin-top:3vw;
    text-transform: lowercase;
    max-width: 250px;
    text-align: center;
}
.quicklinks .nav-item svg,
.quicklinks .nav-item .title,
.quicklinks .nav-item:hover .title,
.quicklinks .nav-item:hover svg {
    transition: all .3s ease;
}
.quicklinks .nav-item:hover svg {
    fill: #fff;
}
.quicklinks .nav-item:hover .title {
    color: #fff;
}
.t-page .quicklinks {
    padding: 6vw 0 3vw 0;
}
.t-contact .quicklinks.contentwrap,
.t-faq .quicklinks {
    margin-top: 5vw;
    border-top: 2px solid $dark-pink;
    padding-top: 6vw;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .t-page .quicklinks {
        padding: 6vw  2vw;
        width: 90%;
    }
}

@media only screen and (max-width: 760px) {
    .quicklinks .navbar-nav.flex-row {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
    }
    .quicklinks svg {
        height: 33vw;
        margin: 10vw 0 2vw;
    }
    .quicklinks span.title {
        font-size: 4vw;
        text-align: center;
    }
}
@media only screen and (max-width: 560px) {
    .quicklinks .title {
        max-width: 100vw;
        width: 100%;
    }
    .quicklinks .nav-item {
        width: 60vw;
    }
    .quicklinks svg {
        max-height: 200px;
    }
}
@media only screen and (max-width: 460px) {
    .quicklinks span.title {
        font-size: 4.5vw;
    }
}