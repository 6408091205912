/*============ Contact ============*/
.contact-wrapper > .contentwrap {
    margin-top: 2vw;
}
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    box-sizing: border-box;
    color: $dark-pink;
    font-weight:600;
}
.contact-details.has-map {
    padding-right: 10px;
}
.contact-details p {
    margin: 20px 0;
}
.contact-details .faq {
    text-decoration: underline;
}
.opening-hours {
    display: block;
    font-weight: 300;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}
.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 50vh;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}


@media only screen and (max-width: 760px) {

/** form **/
    #map-click {
        display: block;
    }
}