/*============ footer ============*/
footer.bottom {
    background: $pale-pink;
    min-height:100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
}
footer div.footer-bottom {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    bottom:0;
    width: 100vw;
    min-height: 50vh;
    background: $footer-background;
    background-size:cover;
    color:$pale-pink;
    padding-top: 3vw;
}
div.footer-bottom::before {
    content:'';
    position: absolute;
    background-image: url("../assets/footer-divider.svg");
    top: 1px;
    padding-top: 26%;
    margin-top: -26%;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    z-index:10;
}
div.footer-bottom .flex-row {
    position: relative;
    z-index: 20;
}
div.footer-bottom > .flex-row > .flex-row {
    margin: 0 5vw;
    padding-left: 20px;
}
div.footer-bottom .flex-row.flex-end {
    align-items: flex-end;
}
div.footer-bottom .flex-row.center {
    align-items: center;
}
.footer-bottom .pink-ribbon {
    background-image: url("../assets/pink-ribbon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 120px;
    width: 90px;
    margin-bottom: 60px;
    margin-left: -5vw;
}
footer .flex-row p,
.site-map {
    font-weight: 600;
}
footer p.item {
    margin: 1.5vw 0;
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}




/*** affiliation banners***/

footer .affiliation.contentwrap {
    max-width: 1200px;
    margin: 1vw 0 0 5vw;
    position: relative;
    z-index: 9;
}
footer .banners-affiliations .banner-stage {
    padding-top: 0px;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
}
footer .banner-stage::after {
    content:'';
    display: none;
}
footer .banners-affiliations .banner {
    width: 200px;
    height: 100px;
    padding: 0px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 3vw 1vw 0;
}
footer .banners-affiliations .banner:last-of-type {
    margin-bottom: 20vw;
}
footer .banners-affiliations .banner:first-of-type {
    margin-left:0;
}
footer .banners-affiliations .banner-content,
footer .banners-affiliations .banner-navigation {
    display: none;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1460px) {
    .footer-bottom > div.flex-end.flex-row {
        flex-direction: column;
        align-items: flex-start;
    }

}
@media only screen and (max-width: 960px) {

}
@media only screen and (max-width: 760px) {

}