.related-content-background {
    position: relative;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vw 0;
}
.related-content-background:first-of-type {
    padding-top: 0;
}
.related-content-background:last-of-type {
    min-height: 60vh;
    padding-bottom:0;
}
.related-content-feed .related-content-background:nth-of-type(even) {
    background-image: url('../assets/stars.jpg');
    background-size: cover;
    color: $pale-pink;
}
.related-content-feed .related-content-background:nth-of-type(even)::after,
.banner-stage::after {
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: $stars-overlay;
}
.related-content,
.related-content-graphic {
    position: relative;
    z-index: 9;
}
.related-content-background.has-graphic .related-content-item {
    justify-content: space-between;
}
.related-content-background.has-graphic:nth-of-type(even) .related-content-item {
    flex-direction: row-reverse;
}
.related-content-background.has-graphic .related-content,
.related-content-background.has-graphic .related-content-graphic {
    width: 45%;
}
h1.rc-title {
    font-size: 35px;
}
h1.rc-title::after {
    background-color: $dark-pink;
}
.rc-title,
a.related-to {
    color: $dark-pink;
}
.rc-summary {
    margin: 3vw 0 3vw 0;
}
a.related-to {
    text-decoration: none;
    text-transform: lowercase;
    align-items: baseline;
    font-weight: 600;
    margin-bottom: 5px;
}
a.related-to h3 {
    margin: 0 0 0 5px;
    display: inline-block;
    max-width: 90%;
}
a.related-to h3::after {
  display:block;
  content: '';
  border-bottom: solid 2px $dark-pink;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
a.related-to h3:hover:after {
    transform: scaleX(1);
}
a.related-to h3.from-left::after {
  transform-origin:  0% 50%;
}
a.related-to p {
    margin:0;
}
a.related-to.faqs {
    text-transform: uppercase;
}
a.related-to span {
    text-transform: initial;
}
.related-content .button {
    margin-top: 2vw;
    font-weight: 600;
}
a.related-to span.arrow {
    transform: rotate(-90deg);
        width: 15px;
    height:15px;
}
/*============ graphic ============*/

.related-content-graphic {
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    height: 400px;
}
.related-content-graphic::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 90%;
}



/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .related-content-background.has-graphic .related-content,
    .related-content-background.has-graphic .related-content-graphic{
        width: 100%;
    }
    .related-content-graphic {
        max-width: 500px;
        height: unset;
    }
    .related-content-background.has-graphic .related-content-item,
    .related-content-background.has-graphic:nth-of-type(even) .related-content-item {
        align-items: center;
        flex-direction: column;
    }
    .related-content-background.has-graphic {
        padding: 10vw 0;
    }
}

@media only screen and (max-width: 760px) {


}