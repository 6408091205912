/*============ sub page============*/


/*============ graphic ============*/

.page-graphic {
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    max-width: 500px;
    width:100%;
    height: 500px;
    margin: 0 auto;
}
.page-graphic::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 90%;
}

/*============staff ============*/
h1.staff-title,
h2.staff-subtitle {
    font-size: 18px;
    margin:0;
}
h2.staff-subtitle,
.staff-body p {
    margin-bottom: 2vw;
}
.staff-body {
    margin-bottom: 5vw;
}
.has-graphic .staff-body {
    width: 75%;
    margin-left: 20px;
}
.has-graphic .staff-graphic {
    width: 20%;
}
.staff-content .flex-row.has-graphic {
    flex-direction: row-reverse;
    justify-content: space-between;
}
.has-graphic .staff-graphic {
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    height: 170px;
}
.has-graphic .staff-graphic::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 90%;
}



/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .page-graphic {
        height: unset;
    }


}

@media only screen and (max-width: 760px) {



}