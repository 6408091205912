@import '_colours.scss';
@import '_fonts.scss';
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
textarea {
    resize: vertical;
}
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
body {
    padding:0;
    margin:0;
}
.contentwrap {
    max-width:900px;
    /*width:100%;*/
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}
img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 240px;
    height:auto;
}
.alert {
/* Position alert over mobile menu, as the user can dismiss the alert or scroll the page to get to the mobile menu */
    position: relative;
    z-index: 101;
}
/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}
.site-link img {
    transition: all .5s ease;
}
.site-link:hover img {
    -webkit-filter: brightness(500%);
    filter: brightness(500%);
    transition: all 2s ease;
}
.flex-row {
    display: flex;
    flex-flow: row nowrap;
}

.arrow {
    content:'';
    position: relative;
    background-image: url("../assets/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height:20px;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (hover: hover) and (pointer: fine) {
    .animation-element {
        opacity:0;
    }
    .in-view {
        opacity:1;
        transform: translateY(150px);
        animation: come-in 0.8s ease forwards;
    }
    .already-in-view {
        opacity:1;
        transform: translateY(0);
        animation: none;
    }
    @keyframes come-in {
        to { transform: translateY(0); }
    }
}
@media only screen and (max-width: 960px) {

}

@media only screen and (max-width: 760px) {
    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    a.logo {
        width: 40vw;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */
.hidden {
    display: none !important;
    visibility: hidden;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.invisible {
    visibility: hidden;
}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}
/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}
@import '_header.scss';
@import '_menu.scss';
@import '_quicklinks.scss';
@import '_related.scss';
@import '_page.scss';
@import '_contact.scss';
@import '_form.scss';
@import '_footer.scss';