/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
/** main menu **/
.main-menu {
    text-transform: lowercase;
    position: absolute;
    top:0;
    right: 5vw;
    z-index:30;
}
header .main-menu {
    top: 2vw;
    right: 4vw;
}
.main-menu ul {
    text-align:right;
    line-height:0;
    font-weight: 400;
    display: flex;
    flex-flow: column nowrap;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    line-height:1;
}
ul > li {
    transition: all .3s ease;
}
ul > li:hover {
    color: #fff;
}
.t-home ul > li:hover,
footer ul > li:hover {
    color: $menu-hover;
}
.main-menu li.depth-0 {
    vertical-align:middle;
}
.main-menu a {
    padding:15px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:#f6f6f6;
    color:$pale-pink;
    z-index:10000;
    text-align: left;
    display:none;
}
.main-menu li:hover > ul {
    display:block;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}

/** site map ***/
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding: 20px 0 0 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    text-transform: uppercase;
    display:block;
    padding-left:30px;
    position:relative;
    font-size: 13px;
}

#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $blue;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: $dark-pink;
    width:250px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -250px;
	overflow-y:auto;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu h3 {
	padding: 1em;
	color:$dark-pink;
	background:$pale-pink;
	margin:0;
	border-bottom: 1px solid $dark-pink;
    border-left: 1px solid $dark-pink;
    text-transform: uppercase;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:$pale-pink;
}
.slide-menu ul ul a {
	color: $dark-pink;
	font-weight: 600;
}

.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid $pale-pink;
	padding: 18px;
	text-decoration:none;
	text-transform: lowercase;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid $pale-pink;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $pale-pink;
	color: $dark-pink;
	font-weight:600;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: $pale-pink;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    height: 54px;
    width: 50px;
    cursor: pointer;
    margin:0;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1460px) {
    .site-map > ul {
        margin-left: 5vw;
        padding-left: 20px;
    }
    .site-map a {
        padding: 0 30px 0 0;
    }
}
@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .header-menu,
    .main-menu,
    .site-map {
        display:none;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
}
