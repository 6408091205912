/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
form p {
    position: relative;
    margin: 5px 0;
    width: 100%;
}
form p:nth-of-type(-n+4) {
    width: 49%;
}
label.float,
input {
    height: 40px;
    top:10px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff78;
    border: 1px solid $pale-pink;
    transition: all .4s ease;
}
input:hover,
textarea:hover {
    border: 1px solid $dark-pink;
    transition: all .4s ease;
    background-color: $form-field-hover;
}
textarea {
    min-height: 80px;
    padding-top: 10px;
}
label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    top: -15px;
    font-size: 0.75em;
}
form .right-align {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}
form button {
    width: 100px;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    font-size: 15px;
    padding: 10px 20px;
}
.recaptcha-notice {
    width:100%;
    color: $dark-pink;
    text-align: right;
}