/*  Header */

header.top {
    position: absolute;
    width: 100%;
    font-family: "gotham", sans-serif;
    font-size: 14px;
    z-index: 10;
    color: $pale-pink;

}
header .flex {
    position: relative;
    z-index: 20;
    height: 100%;
}

header .flex .heading {
    display: flex;
}


/*  Banner */
.banners-home .banner-stage {
    position: relative;
}
.banner-content,
.banners-home .banner-navigation {
    display:none;
}
.banners-home .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
}

/*  Index page Header */
.t-home header.top {
    height: 100vh;
}
.t-home header a.logo {
    max-width: 350px;
    width: 100%;
}
.t-home header.top::after {
    content:'';
    position: absolute;
    background-image: url("../assets/home-divider.svg");
    bottom: -1px;
    padding-top: 26%;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    z-index:10;
}
.t-home header .flex {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}
.t-home header .flex .heading {
    max-width: 450px;
    text-align: center;
    align-items: center;
    flex-flow: column nowrap;
    line-height: 1.6;
}
.t-home .subtitle {
    color: $dark-pink;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px;
}

header .arrow {
    animation: shakevert 6s;
    animation-iteration-count: infinite;
}
@keyframes shakevert {
	0% { transform: translate(0, 5px); }
	10% { transform: translate(0, -1px); }
	20% { transform: translate(0, 5px); }
	30% { transform: translate(0, -1px); }
	40% { transform: translate(0, 5px); }
	50% { transform: translate(0, -1px); }
	60% { transform: translate(0, 5px); }
	70% { transform: translate(0, -1px); }
	80% { transform: translate(0, 5px); }
	90% { transform: translate(0, -1px); }
	100% { transform: translate(0, 5px); }
}

/*  Index page banner */
.t-home .banners-home .banner-stage {
    padding-top:100vh;
}


/*  sub page header */
.t-page header.top {
    background: $header-background;
    height: 250px;
}
.t-page header a.logo {
    position: absolute;
    top: 1vw;
    left: 3vw;
}
.t-page .flex .heading {
    height: 100%;
    align-items: flex-end;
}
.t-page .heading .title {
    padding-bottom: 5vw;
    font-size: 40px;
    width:100%;
}
.t-page .flex .heading .title::after,
h1.rc-title::after {
    content: '';
    position: relative;
    display: block;
    width: 280px;
    height: 1px;
    background-color: $pale-pink;
    margin-top: 0.5vw;
    margin-left: -8px;
}

/*  sub page banner */
.t-page .banners-home .banner-stage {
    padding-top: 250px;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .t-page .heading .title {
        text-align: center;
    }
    .t-page .flex .heading .title::after {
        margin: 0 auto;
    }

}

@media only screen and (max-width: 760px) {
    .t-home header.top {
        height:80vh;
    }
    .t-home .banners-home .banner-stage {
        padding-top: 80vh;
    }
    .t-page .heading .title {
        font-size: 8vw;
    }
    .t-page .flex .heading .title::after,
    h1.rc-title::after {
        width: 50vw;
    }
}
@media only screen and (max-width: 560px) {
    .t-page .flex .heading {
        align-items: center;
    }
    .t-page .heading .title {
        padding-bottom: 0;
        margin-top: 15vw;
    }
    /*  sub page header */
    .t-page header.top {
        height: 55vw;
    }
    /*  sub page banner */
    .t-page .banners-home .banner-stage {
        padding-top: 55vw;
    }
}
